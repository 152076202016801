// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	$('.row-gcom-intro-header-slider:not(.one-slide) .flexslider').flexslider({
		animation: "slide",
		prevText: "",
		nextText: "",
		controlNav: false,
		directionNav: true,
	});

	// Initialize the category dropdown
	$('.jump-links-select').selectric({
		responsive: true,
		inheritOriginalWidth: true,
		arrowButtonMarkup: '<i class="far fa-angle-down"></i>',
		disableOnMobile: false,
		nativeOnMobile: false,
		inheritOriginalWidth: true,
	});

	// When an option is selected
		$('.jump-links-select').on('selectric-select', function(event, element, selectric) {
			console.log('selectric clicked, value is ' + element.value);

			if ( element.value[0] === '#' ) {
				// Fix header to top on scroll
				if ( $('#wpadminbar').length ) { // There is an admin bar present
					var wpadminBarHeight = $('#wpadminbar').height();
				} else {
					var wpadminBarHeight = 0;
				}
				var navigation = $("header");
				var scrollTargetPosition = navigation.height() + wpadminBarHeight;
				$([document.documentElement, document.body]).animate({
				    scrollTop: $(element.value).offset().top-scrollTargetPosition
				}, 2000);
			} else {
				document.location.href = element.value;
			}

		 });

		//Add a hidden label around the selectric input for accessibility.
		// console.log('selectric fix');
		$(".selectric-input, .selectric-hide-select").wrap("<label style='position: absolute; left: -10000px;' aria-hidden='true'>Hidden Label</label>");
}); /* end of as page load scripts */